import { DELETE_SHAREHOLDER } from "./graphql/mutation";

export const ERROR_MESSAGES = {
  VALID_EMAIL: "Please enter a valid email address",
  VALID_PASSWORD: "Please enter a valid password",
  PWD_LENGTH: "Password should be minimum of 8 characters",
  ERROR: "Error",
  FOOTER_TXT: "All Rights Reserved by",
  PHEONIX_TXT: "    Pheonixsolutions",
  TERMS:"Terms and Conditions"
};
export const TEXT_MESSAGES = {
  NO_LOANS:"No loan details available",
  OTHERS:"Others",
  Quick_Views:"Quick Views",
  EMAIL_REG: /^[a-zA-Z0-9._%+-]+@(?!.*\.\w{0,2}$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  Log_SUCCESS: "Login Successful",
  ENTER: "Enter",
  DFINANCE: "DHEERAN FINANCE",
  TEXT_MEG:"In the works, coming your way!",
  LOGIN: "Login",
  PROFILE: "Profile",
  LOGOUT: "Logout",
  HI: "Hi, ",
  DF: "Dheeran Finance",
  SAVE: "SAVE",
  CLOSE: "CLOSE",
  ADD_BORROWER: "ADD BORROWER",
  ADD_VOUCHER: "ADD VOUCHER",
  BORROWER: "Borrower",
  VOUCHER: "Voucher",
  ADD_BORROWER_title: "Add Borrower",
  ADD_VOUCHER_title: "Add Voucher",
  EDIT_VOUCHER_title: "Edit Voucher",
  VIEW_VOUCHER_TITLE: "View Voucher",
  EDIT_BORROWER_title: "Edit Borrower",
  VIEW_BORROWER_TITLE: "View Borrower",
  ADD_DEPOSITOR: "ADD DEPOSITOR",
  ADD_CATEGORY:" + Add Sub Category",
  DEPOSITOR: "Depositor",
  ADD_DEPOSITOR_title: "Add Depositor",
  EDIT_DEPOSITOR_title: "Edit Depositor",
  ADD_SHAREHOLDER_title: "Add Shareholder",
  SHAREHOLDER: "Shareholders",
  ADD_SHAREHOLDER: "ADD SHAREHOLDER",
  EDIT_SHAREHOLDER_title: "Edit Shareholder",
  VIEW_SHAREHOLDER_TITLE: "View Shareholder",
  VIEW_DEPOSITOR_TITLE: "View Depositor",
  DELETE_SUCCESS: "Depositor deleted successfully",
  DELETE_SUCCESS_borrower: "Borrower deleted successfully",
  DELETE_SUCCESS_voucher: "Voucher deleted successfully",
  DELETE_SHAREHOLDER: "Shareholder deleted successfully",
  ADD_EDIT_ERROR: "Error",
  ACCOUNTS:"Accounts",
  ADD_EDIT_ERROR_borrower:
    "Phone number already exists.",
  DIPOSITORCREATE: "Depositor added successfully",
  BORROWERCREATE: "Borrower added successfully",
  BORROWERUPDATE: "Borrower updated successfully",
  VOUCHERCREATE: "Voucher added successfully",
  VOUCHERUPDATE: "Voucher updated successfully",
  DIPOSITORUPDATE: "Depositor updated successfully",
  DIPOSITORDEL: "Depositor deleted successfully",
  SHAREUPDATE: "Shareholder updated successfully",
  SHAREADD: "Shareholder added successfully",
  ADD_EDIT_SHARE: "An error occurred while adding or editing the shareholder",
  DELETE_CONFIRMATION: "Delete Confirmation",
  DELETE_SUCCESSFULL: "Product deleted successfully",
  DELETE_MSG: "Are you sure you want to delete this ",
  CLOSE1: "Close",
  CANCEL:"Cancel",
  DELETE:"Delete",
  DELETE_ERROR: 'Delete failed.',
  DELETE_ERROR1: 'Cannot delete borrower: associated loans exist',
  AMT_ERR1:"Amount must not contain alphabets",
  AMT_ERR2:"Amount must not contain special characters",
  AMT_ERR3: "Amount must be a number with up to two decimal places",
  Primary_voucher:"Primary and Secondary numbers cannot be the same",
  DEPOSIOTR_DETAILS:"Depositor Details",
  DEPOSIOTR_DETAILS1:"Depositor Information",
  BORROWER_DETAILS:"Borrower Details",
  DEPOSIOTR:"Depositor",
  Vochr_no:`Voucher No must contain only numbers`,
  Loan_no:`Loan No must contain only numbers`,
  BORROWER_LOAN_INFO:"Basic Loan Information",
  LOAN_DETAILS:"Loan Details",
  PERSONAL_DETAILS:"Personal Details",
  SELECT_TAB:"Select a loan tab to view details",
  BACK_TO: "Back To Depositor List",
  BACK_TO1: "Back To Borrower List",
  Depositor_Information:"Depositor Information",
  No_Depositor:"No depositor data available.",
  No_Borrower:"No borrower data available.",
  INVALID_INTEREST_RATE: "Please enter a valid interest rate. Only positive numbers and a '%' sign are allowed.",
  VALID_INPUT:"Valid input for Interest Rate.",
  PRIMARY_VALID:"Primary Number and Secondary Number cannot be the same"

};
export const Receipt_msg ={
  RECEIPT:"Receipt",
  ADD_RECEIPT:"ADD RECEIPT",
  MODAL_TITLE:"Add Receipt",
  VIEW_RECEIPT_TITLE: "View Receipt",
  EDIT_RECEIPT:"Edit Receipt",
  FAILED_DELETE:"Failed to delete receipt.",
 RECEIPTCREATE: "Receipt added successfully",
  RECEIPTUPDATE: "Receipt updated successfully",
  DELETE_SUCCESS_receipt: "Receipt deleted successfully",
  NAME_REQ:`Name is required`,
  SELECT_ROLE:"Select Receipt role type",
}
export const required_fields =["Borrower Name" || "Depostor Name" || "Shareholder Name", "Amount"];
export const labels = {
 Role_type:"Select Receipt role type",
 B_NAME: "Borrower Name",
 D_NAME:"Depositor Name",
 S_NAME:"Shareholder Name",
 BORROWER:"Borrower",
 DEPOSITOR:"Depositor",
 SHAREHOLDER:"Shareholder",
 NAMES: ["Borrower Name", "Depositor Name", "Shareholder Name"],
 required: ["Select Receipt role type", "Borrower Name", "Depositor Name", "Shareholder Name", "Amount", "Remarks"],
 LOAN_NO:"Loan No",
 Amount:"Amount",
 Receipt_Type:"Receipt Type",
 up_to_date:"Up To Date",
 date:"Date",
 Remarks:"Remarks",
}

export const receiptTypes = ["Daily loan interest", "Monthly loan interest","Penality interest" ];

export const Collection_msg ={
 COLLECTION:"Daily Collection",
  ADD_COLLECTION:"ADD DAILY COLLECTION",
  MODAL_TITLE:"Add Daily Collection",
  VIEW_COLLECTION_TITLE: "View Daily Collection",
  EDIT_COLLECTION:"Edit Daily Collection",
 COLLECTIONCREATE: "Daily Collection added successfully",
  COLLECTIONUPDATE: "Daily Collection updated successfully",
  DELETE_SUCCESS_receipt: "Daily Collection deleted successfully",
  Collection_Receipt:"Daily Collection Receipt",
  Download:"Download",
  DATE:"Date",
  SELECT_DATE:"Select a Date ",
  SELECT_DATE1:"To view the collection Details",
  collection_receipt:'collection_receipt.pdf'
}
export const Payment_msg ={
  PAYMENT:"Payment",
   ADD_PAYMENT:"ADD PAYMENT",
   MODAL_TITLE:"Add Payment",
   VIEW_paymnet_TITLE: "View Payment",
   EDIT_PAYMENT:"Edit Payment",
  PAYMENTCREATE: "Payment added successfully",
   PAYMENTUPDATE: "Payment updated successfully",
   DELETE_SUCCESS_receipt: "Payment deleted successfully",
   FAILED_PAYMENT:"Failed to delete payment.",
   INVALID:"Invalid amount. Please enter a valid number.",
  }
  export const categories = ["Interest Paid for Depositor", "Deposit Return", "Expenses"];
export const defaultCategories = [
  "Salary", "Transport", "Rent", "E.B Bill", "Pooja Expense",
  "Stationary", "Furniture", "Miscellaneous", "Chit Payment", "Office advance"
];
export const baseurl = {
  DEV: "https://dev-api-dfinance.pheonixsolutions.com/graphql",
  UAT: "https://uat-api-dfinance.pheonixsolutions.com/graphql",
PROD: "https://api-dfinance.pheonixsolutions.com/graphql",
LOCAL: "http://localhost:5000/graphql",
}
