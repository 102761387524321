import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import PheonixSideBarProps from '../Interfaces/PheonixSideBarProps';

const PheonixSideBar: React.FC<PheonixSideBarProps> = ({ icon, text, to, selected }) => {
  const location = useLocation();
  const isSelected = location.pathname === to || selected;

  return (
    <ListItem
      sx={{
        textDecoration: 'none',
        backgroundColor: isSelected ? '#F2FCFF' : 'transparent',
        color: 'BLACK',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        borderRight: isSelected ? '4px solid #208BEE' : 'none',
        '&:hover': { backgroundColor: '#F2FCFF', textDecoration: 'none' },
        fontSize: '20px',
        '& .MuiTypography-root': {
          fontSize: '18px',
          fontWeight: '700',
        },
      }}
      component={Link}
      to={to}
    >
      <ListItemIcon sx={{ color: isSelected ? 'GREY' : 'inherit' }}>
        {icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default PheonixSideBar;
