import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SummaryItem, AccountssummaryProps } from '../Interfaces/AccountsProps';

const Accountssummary: React.FC<AccountssummaryProps> = ({
  items,
  titleVariant = "h5",
  valueVariant = "h5",
  valueFontWeight = 700,
}) => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobileSmall ? 'column' : 'row',
          justifyContent: 'space-between',
          marginTop: isMobileSmall ? "15px" : isMedium ? "10px" : "40px",
          gap: isMobileSmall ? "20px" : isMedium? "20px":"20vh", 
        }}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            sx={{
              flex: 1,
              textAlign: 'left',marginLeft:"20px"
            }}
          >
            <Typography
              variant={titleVariant}
              style={{
                fontSize: isMobileSmall ? "13px" : isMedium ? "12px" : "16px", fontWeight: valueFontWeight
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant={valueVariant}
              style={{
                fontWeight: valueFontWeight,
                fontSize: isMobileSmall ? "13px" : isMedium ? "12px" : "16px",
              }}
            >
              {typeof item.value === "number"
                ? item.value.toLocaleString()
                : item.value}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Accountssummary;
