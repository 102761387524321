import { gql } from "@apollo/client";

export const GET_BORROWERS = gql`
  query GetBorrowerperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getBorrowerperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      borrower {
        id
        borrower_name
        borrower_id
        phone1
        phone2
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const GET_ALL_BORROWERS = gql`
  query GetAllBorrowers {
    getAllBorrowers {
      id
      borrower_name
      borrower_id
      address1
      address2
    }
  }
`;
export const GET_ALL_DEPOSITORS = gql`
  query GetAllDepositors {
    getAllDepositors {
      id
      depositor_name
      depositor_id
    }
  }
`;
export const GET_ALL_SHAREHOLDERS = gql`
  query GetAllShareholder {
    getAllShareholder {
      id
      shareholder_name
      shareholder_id
    }
  }
`;
export const GET_BORROWERS_VOUCHER = gql`
  query GetAllBorrowers {
    getAllBorrowers {
      id
      borrower_name
      borrower_id
    }
  }
`;
export const GET_DEPOSITORS = gql`
  query GetDepositorperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getDepositorperPage(page: $page, perPage: $perPage,searchQuery: $searchQuery) {
      totalPages
      totalCount
      depositor {
        id
        depositor_name
        depositor_id
        phone1
        phone2
        interest_rate
        deposit_amount
        address1
        address2
        occupation
        remarks
      }
    }
  }
`;
export const GET_BORROWER_BY_ID = gql`
  query GetBorrowerDetails($id: ID!) {
    getBorrowerDetails(id: $id) {
      message
        borrowerDetails {
            borrower {
                id
                borrower_name
                borrower_id
                phone1
                phone2
                address1
                address2
                occupation
                remarks
            }
            totals {
                total_loan_nos
                total_loan_amt
                total_paid_amt
                total_pending_amt
                total_interest_paid
                total_penalty_interest
            }
        }
    }
  }
`;

export const GET_LOAN_DETAILS = gql`
  query GetLoanDetails($loan_no: Int!) {
    getLoanDetails(loan_no: $loan_no) {
      message
      loanDetails {
        loan {
          loan_type
          loan_no
          loan_amt
          loan_start_date
          interest_rate
          interest_amount
          loan_end_date
          number_of_days
          current_day_count
          excess_day_count
          pending_months
          interest_paid
          interest_pending
        }
        others {
          guarantee_name
          guarantee_address1
          guarantee_address2
          guarantee_phone1
          guarantee_phone2
          document_details
        }
      }
    }
  }
`;

export const GET_DEPOSITOR_BY_ID = gql`
  query GetDepositorbyIDDetails($depositor_id: String!) {
    getDepositorbyIDDetails(depositor_id: $depositor_id) {
   id
        depositor_name
        depositor_id
        phone1
        phone2
        interest_rate
        deposit_amount
        address1
        address2
        occupation
        remarks
        total_interest_paid
      
    }
  }
`;

export const GET_SHAREHOLDERS = gql`
  query GetShareholderperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getShareholderperPage(page: $page, perPage: $perPage,searchQuery: $searchQuery) {
      totalPages
      totalCount
      shareholder {
        id
        shareholder_name
        shareholder_id
        phone1
        phone2
    share_amount
        address1
        address2
     remarks
      }
    }
  }
`;
export const GET_ALL_VOUCHERS = gql`
  query GetAllVouchers {
    getAllVouchers {
      id
      borrower_name
      borrower_id
      loan_no
    }
  }
`;
export const GET_ALL_LOANNOS = gql`
  query GetAllLoanNumbers {
    getAllLoanNumbers
  }
`;
export const GET_LOAN_NUMBERS_BY_BORROWER = gql`
  query GetLoanNumbersByBorrower($borrowerId: String!) {
    getLoanNumbersByBorrower(
   borrower_id: $borrowerId
    )
   {
    loan_no
   loan_type
   }
  }
`;
export const GET_LOAN_NUMBERS_BY_BORROWER_RECEIPTTYPE = gql`
query GetLoanNumbersByBorrowerAndReceiptType($borrowerId: String!, $receipt_type: String!) {
  getLoanNumbersByBorrowerandReceipttype(borrower_id: $borrowerId, receipt_type: $receipt_type) {
    loan_no
    loan_type
  }
}

`;
export const GET_AMOUNT_BY_LOANNO = gql`
  query GetAmountByBorrowerAndLoanNo($borrowerId: String!, $loanNo: Int!) {
    getAmountByBorrowerAndLoanNo(
   borrower_id: $borrowerId
   loan_no: $loanNo
    )
   {
    amount
  }
  }
`;
export const GET_BORROWER_BY_LOAN_NO = gql`
  query GetBorrowerByLoanNumbers($loanNo: Int!) {
    getBorrowerByLoanNumbers(loan_no: $loanNo) {
      borrower_name
      borrower_id
    }
  }
`;
export const GET_VOUCHERS = gql`
  query GetVoucherperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getVoucherperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      voucher {
        id
        borrower_name
        borrower_id
        address1
        address2
        voucher_no
        date
        loan_type
        loan_no
        loan_amt
        interest_rate
        interest_amount
        guarantee_name
        guarantee_phone1
        guarantee_phone2
        guarantee_address1
        guarantee_address2
        document_details
        remarks
      }
    }
  }
`;
export const GET_RECEIPTS = gql`
  query GetReceiptperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getReceiptperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
      totalPages
      totalCount
      receipt {
        id
        name
        name_id
        uptodate
        receipt_no
        loan_no
        receipt_type
        amount
        remarks
        role_type
      }
    }
  }
`;
export const GET_COLLECTION = gql`
  query GetCollectionperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getCollectionperPage(page: $page, perPage: $perPage,searchQuery: $searchQuery) {
      totalPages
      totalCount
      collection {
        id
        borrower_name
        borrower_id
        collection_no
        loan_no
        date
        amount
      }
    }
  }
`;
export const GET_COLLECTION_RECEIPT = gql`
  query GetCollectionReceipt($date: String!) {
    getCollectionReceipt(date: $date) {
      collection_receipt_number
      loan_nos
      total_amount_collected
         DownloadReceipt {
       details {
                loan_no
                name
                amount
            }
        }
    }
  }
`;

export const GET_PAYMENTS = gql`
  query GetPaymentperPage($page: Int!, $perPage: Int!, $searchQuery: String) {
    getPaymentperPage(page: $page, perPage: $perPage,searchQuery: $searchQuery) {
      totalPages
      totalCount
      payment {
        id
        category_name
         sub_category
        sub_category_name
        depositor_id
        payment_id
        amount
        date
        remarks
      }
    }
  }
`;
export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    getAllCategories {
      sub_category_name
    }
  }
`;