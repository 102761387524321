import React from 'react';
import { Grid, Typography } from '@mui/material';

interface DetailRowProps {
  label: string;
  value: string | number;
  style?: React.CSSProperties;
}

const DetailRow: React.FC<DetailRowProps> = ({ label, value,style }) => (
  <Grid item xs={12} sm={6} md={2.4}>
    <Typography variant="body1"style={{fontFamily:"Roboto",fontWeight:400,fontSize:"16px",color:"#000000"}} >{label}</Typography>
    <Typography style={{fontFamily:"Roboto",fontWeight:600,fontSize:"14px",color:"#000000",marginTop:"5px",...style}}>{value}</Typography>
  </Grid>
);

export default DetailRow;
