import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client';
import baseUrl from "./Api";
import App from './App';
import Dashboard from './Pages/dashboard';
import Layout from './Pages/Layout';
import Borrowers from './Pages/Borrowers';
import Depositors from './Pages/Depositors';
import Shareholders from './Pages/Shareholders';
import Voucher from './Pages/Voucher';
import Collection from './Pages/Collection';
import Report from './Pages/Report';
import ReceiptScreen from './Pages/Receipt';
import Payment from './Pages/Payment';
import Loanledger from './Pages/Loanledger';
import Generalledger from './Pages/Generalledger';
import Accounts from './Pages/Accounts';
import { SidebarProvider } from './Pages/SidebarContext';
import DepositorDetails from './Pages/DepositorDetails';
import BorrowerDetails from './Pages/BorrowerDetails';
const client = new ApolloClient({
  uri: baseUrl, 
  cache: new InMemoryCache(),
});
const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/dashboard" element={<Layout title="Dashboard"><Dashboard /></Layout>} />
      <Route path="/borrowers" element={<Layout title="Borrowers"><Borrowers /></Layout>} />
      <Route path="/depositors" element={<Layout title="Depositors"><Depositors /></Layout>} />
      <Route path="/shareholders" element={<Layout title="Shareholders"><Shareholders /></Layout>} />
      <Route path="/voucher" element={<Layout title="Voucher"><Voucher /></Layout>} />
      <Route path="/receipt" element={<Layout title="Receipt"><ReceiptScreen /></Layout>} />
      <Route path="/dailycollection" element={<Layout title="Daily Collection"><Collection /></Layout>} />
      <Route path="/payment" element={<Layout title="Payment"><Payment /></Layout>} />
      <Route path="/report" element={<Layout title="Report"><Report /></Layout>} />
      <Route path="/loanledger" element={<Layout title="Loan Ledger"><Loanledger /></Layout>} />
      <Route path="/generalledger" element={<Layout title="General Ledger"><Generalledger /></Layout>} />
      <Route path="/accounts" element={<Layout title="Accounts"><Accounts /></Layout>} />
      <Route path="/depositordetails" element={<Layout title="Depositor Details"><DepositorDetails /></Layout>} />
      <Route path="/borrowerdetails" element={<Layout title="Borrower Details"><BorrowerDetails /></Layout>} />
</Routes>
  );
};
const rootElement = document.getElementById('root');
document.body.style.backgroundColor = "white";
if (rootElement) {
  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router>
        <SidebarProvider>
            <AppRouter />
          </SidebarProvider>
        </Router>
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
}