import React from 'react';
import { Grid } from '@mui/material';
import QuickViewCard from './QuickViewCard';
import { QuickViewGridProps } from '../Interfaces/QuickViewCardProps';

const QuickViewGrid: React.FC<QuickViewGridProps> = ({ items }) => {
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid 
          item 
          xs={12}    
          sm={6}    
          md={4}  
          lg={2.3}     
          key={index}
        >
          <QuickViewCard title={item.title} icon={item.icon} value={item.value} linkTo={item.linkTo} />
        </Grid>
      ))}
    </Grid>
  );
};
export default QuickViewGrid;