import React, { useState } from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  MenuItem,
  IconButton,
  Menu,
  Box,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppBarProps from "../Interfaces/AppBarProps";
import logouticon from "../assets/Logout.png";
import { TEXT_MESSAGES } from "../constant";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Dlogo.svg";
import themestyle from "../theme";

const AppBar: React.FC<AppBarProps> = ({ title, children, isSidebarOpen }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMobileMenuAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/");
  };

  const open = Boolean(anchorEl);
  const mobileMenuOpen = Boolean(mobileMenuAnchorEl);
  const titleMarginLeft = isSidebarOpen ? "260px" : "100px";

  return (
    <MuiAppBar
      position="static"
      style={{
        background: isMobile ? "linear-gradient(to right, #74B1EA, #82E2F7)" : "white",
        borderBottom: "2px solid #ccc",
        boxShadow: "0px 4px 4px 0px #00000000",
      }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isMobile ? (
          <>
            <img
              src={logo}
              alt="Logo"
              style={{
                marginLeft: "50px",
                color: "white",
                width:"81px",
                height:"30px"
              }}
            />

            <img
              src={logouticon}
              alt="Logout Icon"
              style={{ width: "29px", height: "29px" }}
              onClick={handleLogout}
            />

            <Menu
              id="menu-appbar"
              anchorEl={mobileMenuAnchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={mobileMenuOpen}
              onClose={handleClose}
            ></Menu>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: titleMarginLeft,
                }}
              >
                
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleLogout}
              >
                <img
                  src={logouticon}
                  alt="Logout Icon"
                  style={{ width: "40px", height: "40px", cursor: "pointer" }}
                />
              </Box>
            </Box>
          </>
        )}
        {children}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
