const themestyle = {
  colors: {
    primary: "#208BEE",
    Primary2:"#3D3D4E",
    disabled:"#bfbfbf",
    secondary: "#DE0093",
    fontfamily: "Red Hat Display",
    error:"#D32F2F",
    sans:"Sans Serif",
    dftitlecolor:" #1A504CDE",
    login_backgroundcolor:"#F2FCFF",
    pagetitle:"#274E99",
    white:"#FFFFFF",
    gray:"gray",
    black:"#333333",
    black1:"#1C1B1F",
    green:"#1EBB2E",
  },
  fontSizes: {
    xsmall: "16px",
    small: "18px",
    medium: "24px",
    large: "32px",
    xlarge: "32px",
    sm:"20px"
  },
  spacing: {
    small: "10px",
    medium: "20px",
    large: "30px",
    xlarge: "40px",
  },
  fontWeight: {
    xs:400,
    sm:700,
    md: 900
  },
  breakpoints: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1030,
    xl: 1920,
  },
};
export default themestyle;
