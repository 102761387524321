import React, { useState, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
  Typography,
  MenuItem,
  DialogActions,
  Dialog,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TEXT_MESSAGES } from "../constant";
import { PaymentModalFormData } from "../Interfaces/PaymentModalProps";
import { AddPaymentModalProps } from "../Interfaces/PaymentModalProps";
import { useMediaQuery, useTheme,Popper, PopperProps} from "@mui/material";
import themestyle from "../theme";
import PheonixTextarea from "./PheonixTextArea";
import { categories,defaultCategories } from "../constant";
import { styled } from "@mui/system";
const CustomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    modifiers={[
      {
        name: "flip",
        enabled: false,
      },
      {
        name: "preventOverflow",
        options: {
          altBoundary: true,
          tether: false,
          rootBoundary: "document",
        },
      },
    ]}
    sx={{
      "& .MuiAutocomplete-listbox": {
        maxHeight: "210px", 
        overflow: "auto",
      },

    }}
  />
);
const AddPaymentModal: React.FC<AddPaymentModalProps> = ({
  open,
  onClose,
  onAdd,
  fields,
  formData,
  setFormData,
  fieldErrors,
  setFieldErrors,
  title,
  editable = false,
  categorydata,
  depositordata,
  setShowCategoryInput,
  showCategoryInput,
}) => {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [initialFormData, setInitialFormData] = useState<PaymentModalFormData>({
    ...formData,
  });
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const requiredFields = [
    "Amount", 
    ...(showCategoryInput ? ["Sub Category Name"] : []),
    ...(formData["Category"] === "Expenses" && !showCategoryInput ? ["Sub Category"] : []),
    ...(formData["Category"] === "Interest Paid for Depositor" || formData["Category"] === "Deposit Return" ? ["Depositor Name"] : []),
] as (keyof PaymentModalFormData)[];
 useEffect(() => {
    if (open) {
      const updatedFormData = { ...formData };
      setInitialFormData(updatedFormData);
      setFormData(updatedFormData);
    }
  }, [open]);

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => error);
    const hasEmptyRequiredFields = requiredFields.some((key) => !formData[key]);
    const normalizeData = (data: PaymentModalFormData) => ({
      ...data,
      Amount: typeof data.Amount === 'string' ? parseFloat(data.Amount) : data.Amount,
    });

    const initialNormalizedData = normalizeData(initialFormData);
    const currentNormalizedData = normalizeData({ ...formData });
    const isFormDataChanged =
      JSON.stringify(initialNormalizedData) !== JSON.stringify(currentNormalizedData);
    setIsConfirmDisabled(
      hasErrors || hasEmptyRequiredFields || !isFormDataChanged
    );
  }, [formData, fieldErrors, requiredFields, initialFormData]);

  useEffect(() => {
    if (showCategoryInput) {
      setFieldErrors((prevErrors) => ({ ...prevErrors, "Sub Category": "" }));
    }
  }, [showCategoryInput]);
const handleBlur = (label: keyof PaymentModalFormData) => {
    const value = formData[label];
    validateField(label, value);
  };
 const validateField = (label: keyof PaymentModalFormData, value: string) => {
    let error = "";
    if (!value && requiredFields.includes(label)) {
      error = `${label} is required`;
    } else if (label === "Sub Category Name" && /\d/.test(value)) {
      error = `${label} cannot contain numbers`;
    }else if (label === "Sub Category Name" && allCategories.some(category => category.toLowerCase() === value.toLowerCase())) {
      error = `${label} already exists`;
  }
  else if (label === "Amount" && value) {
      if (/[a-zA-Z]/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR1;
      } else if (/[^0-9.]/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR2;
      } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        error = TEXT_MESSAGES.AMT_ERR3;
      }
    } else {
      error = "";
    }

    setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: error }));
  };
 const allCategories = Array.from(
    new Set([
      ...defaultCategories,
      ...(categorydata?.map((cat) => cat.sub_category_name) || []),
    ])
  );
  const handleChange = (label: keyof PaymentModalFormData, value: string) => {
  if (label === "Category") {
      setFormData((prevData) => ({
        ...prevData,
        [label]: value,
        "Sub Category": "",
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [label]: value }));
    }
    validateField(label, value);
  };
const [localDefaultCategories, setLocalDefaultCategories] = useState<string[]>([...defaultCategories]);
const [userAddedCategories, setUserAddedCategories] = useState<string[]>([]);
const handleAddSubCategory = () => {
  const newSubCategory = formData["Sub Category Name"];
  if (newSubCategory && !localDefaultCategories.includes(newSubCategory)) {
    setLocalDefaultCategories((prevCategories) => [...prevCategories, newSubCategory]);
  }
};
const getSubCategories = () => {
  if (formData["Category"] === "Expenses") {
    const apiCategories = categorydata?.map(cat => cat.sub_category_name) || [];
 return Array.from(new Set([ ...localDefaultCategories, ...apiCategories,...userAddedCategories]));
}
  return [];
};

const showSubCategoryNameField = () => {
  const selectedCategory = formData["Category"];
  return !(
    selectedCategory === "Interest Paid for Depositor" ||
    selectedCategory === "Deposit Return"
  );
};
 const getDepositorOptions = () => {
    return depositordata?.map(depositor => ({
      label: `${depositor.depositor_id} - ${depositor.depositor_name}`,
      value: depositor.depositor_id,
    })) || [];
  };

return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            style={{
              fontWeight: 700,
              fontSize: isMobileSmall ? "18px" : "24px",
              fontFamily: "Inter",
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="form">
          <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
          <Autocomplete
  fullWidth
  options={categories}
  value={formData["Category"] || ""}
  onChange={(event, newValue) => handleChange("Category", newValue || "")}
  onBlur={() => handleBlur("Category")}
  style={{marginTop:"10px"}}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Category *"
      variant="outlined"
      error={!!fieldErrors["Category"]}
      helperText={fieldErrors["Category"]}
      InputProps={{
        ...params.InputProps,
        readOnly: editable,
      }}
    />
  )}

/>
    </Grid>

    {formData["Category"] === "Interest Paid for Depositor" || formData["Category"] === "Deposit Return" ? (
        <Grid item xs={12} sm={12}>
            <Autocomplete
                options={getDepositorOptions()}
                getOptionLabel={(option) => option.label}
                value={getDepositorOptions().find(opt => opt.value === formData["Depositor Name"]) || null}
                onChange={(event, newValue) => {
                  handleChange("Depositor Name", newValue ? newValue.value : "");
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label="Depositor Name"
                        variant="outlined"
                        error={!!fieldErrors["Depositor Name"]}
                        helperText={fieldErrors["Depositor Name"]}
                        onBlur={() => handleBlur("Depositor Name")}
                        style={{ marginTop: "5px" }}
                        InputProps={{
                            ...params.InputProps,
                            readOnly: editable,
                        }}
                    />
                )}
            />
        </Grid>
    ) : formData["Category"] === "Expenses" ? (
 <Grid item xs={12} sm={6}>
            <Autocomplete
                options={getSubCategories()}
                getOptionLabel={(option) => option}
                value={formData["Sub Category"] || ""}
                onChange={(event, newValue) => {
                    handleChange("Sub Category", newValue || "");
                }}
           renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label="Sub Category"
                        variant="outlined"
                        error={!!fieldErrors["Sub Category"]}
                        helperText={fieldErrors["Sub Category"]}
                        onBlur={() => handleBlur("Sub Category")}
                        style={{ marginTop: "5px"}}
                        InputProps={{
                            ...params.InputProps,
                            readOnly: editable,
                        }}
                    />
                )}
                disabled={showCategoryInput}
                PopperComponent={CustomPopper}
            />
        </Grid>
    ): ""}
    {formData["Category"] === "Expenses"  ? (
  <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowCategoryInput(true)}
                disabled={formData["Category"] !== "Expenses"} 
                style={{
                  height: "46px",
                  marginTop: "10px",
                  fontFamily: "Roboto",
                  width: "100%",
                  fontSize: isMobileSmall ? "12px" : "14px",
                }}
              >
                {TEXT_MESSAGES.ADD_CATEGORY}
              </Button>
            </Grid>
    ): "" }
            {showSubCategoryNameField() && showCategoryInput && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`Sub Category Name *`}
                  value={formData["Sub Category Name"] as string}
                  onChange={(e) =>
                    handleChange("Sub Category Name", e.target.value)
                  }
                  onBlur={() => handleBlur("Sub Category Name")}
                  error={!!fieldErrors["Sub Category Name"]}
                  helperText={fieldErrors["Sub Category Name"]}
                  variant="outlined"
                  style={{ marginTop: "5px" }}
                  InputProps={{
                    readOnly: editable,
                  }}
                />
              </Grid>
            )}
          {["Amount", "Remarks"].map((label, index) => (
              <Grid item xs={12} key={index}>
                {label === "Remarks" ? (
                  <PheonixTextarea
                    label={label}
                    value={
                      formData[label as keyof PaymentModalFormData] as string
                    }
                    onChange={(e) =>
                      handleChange(
                        label as keyof PaymentModalFormData,
                        e.target.value
                      )
                    }
                    onBlur={() => handleBlur(label as keyof PaymentModalFormData)}
                    error={!!fieldErrors[label as keyof PaymentModalFormData]}
                    helperText={
                      fieldErrors[label as keyof PaymentModalFormData]
                    }
                  />
                ) : (
                  <TextField
                    fullWidth
                    label={`${label} *`}
                    value={
                      formData[label as keyof PaymentModalFormData] as string
                    }
                    onChange={(e) =>
                      handleChange(
                        label as keyof PaymentModalFormData,
                        e.target.value
                      )
                    }
                    onBlur={() => handleBlur(label as keyof PaymentModalFormData)}
                    error={!!fieldErrors[label as keyof PaymentModalFormData]}
                    helperText={fieldErrors[label as keyof PaymentModalFormData]}
                    variant="outlined"
                    style={{ marginTop: "5px" }}
                    InputProps={{
                      readOnly: editable,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            backgroundColor: !editable
              ? themestyle.colors.white
              : themestyle.colors.primary,
            color: !editable
              ? themestyle.colors.primary
              : themestyle.colors.white,
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "14px",
            marginBottom: "15px",
            marginRight: "15px",
            padding: "6px 16px",
            width: "76px",
            height: "36px",
          }}
        >
          {TEXT_MESSAGES.CLOSE}
        </Button>
        {!editable && (
                   <Button
                   onClick={onAdd}
            disabled={isConfirmDisabled}
            style={{
              backgroundColor: isConfirmDisabled ? "lightgray" : "#208BEE",
              color: "#ffffff",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
            variant="contained"
          >
            {TEXT_MESSAGES.SAVE}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AddPaymentModal;