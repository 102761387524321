import React, { useState, useEffect } from "react";
import { ERROR_MESSAGES } from "../constant";
import { useSidebar } from "../Pages/SidebarContext";
import { useMediaQuery, useTheme } from "@mui/material";
import themestyle from "../theme";

const PheonixFooter: React.FC<PheonixFooterProps> = ({ style }) => {
  const { isOpen } = useSidebar();
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobileSmall ? "column" : "row",
        justifyContent: isMobileSmall ? "center" : "space-between",
    position: "fixed",
        bottom: isTablet ? 5 : isMobileSmall? -10 : 10,
        left: isMobileSmall ? 0 : isOpen ? 250 : 130,
        width: isMobileSmall ? "100%" : "auto",
        textAlign: "center",
        ...style,
      }}
    >
      <div
        style={{
          fontFamily: "Inter",
          color: "#212121",
          fontSize: "12px",
          fontWeight: 400,
          marginTop: isMobileSmall ? "10px" : 0,
        }}
      >
        &copy;{currentYear}
        <span> {ERROR_MESSAGES.FOOTER_TXT}</span>{" "}
        <a
          href="https://www.pheonixsolutions.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: themestyle.colors.primary,
            fontFamily: "Inter",
            fontWeight: 400,
            textDecoration: "none",
            fontSize: "12px",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
  
        >
          {ERROR_MESSAGES.PHEONIX_TXT}
        </a>
      </div>

      <a
        href="https://www.pheonixsolutions.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          fontFamily: "Inter",
          color: themestyle.colors.primary,
          fontSize: "12px",
          fontWeight: 400,
          marginTop: isMobileSmall ? "10px" : "1px",
          textDecoration: "none",
          position: isMobileSmall? "relative":"fixed",
          bottom:isTablet ? 5 : 10,
          right: isMobileSmall? 0 : 30,
        }}
        onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
  onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
      >
        {ERROR_MESSAGES.TERMS}
      </a>
    </div>
  );
};
export default PheonixFooter;